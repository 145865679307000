
const Car = () => {
    return (
        <div className="Cars">
        <div className="sImage"><img src={process.env.PUBLIC_URL + "./assets/img/car.jpg"} alt="car"/></div>
     

            </div>
    )
}

export default Car
