
import PageBanner from "../components/PageBanner"
import ReviewsThumb from "../components/ReviewsThumb"


const Reviews = () => {
    return (
        <>
             <PageBanner title="Google Reviews"/> 
             <section className="preset offWhiteSec services">
                <div className="container">
  {/* Reviews row */}
    <div className="row multiple">
  

 <ReviewsThumb 
        reviewstext="Excellent service. they were easy to find online and very friendly. i will definitely be recommending and using their service again."
        reviewerName="BIRRAH ISMAIL"
        rdate="1/15/2020"/>

<ReviewsThumb 
        reviewstext="Exceptional service everytime. Professional, friendly chauffeur, Always reliable. Thankyou"
        reviewerName="KAY"
        rdate="2/06/2020"/>

<ReviewsThumb 
        reviewstext="Easy to book, good price and great staff make GEC a company I will definitely use again and recommend to others. 5 Stars"
        reviewerName="YASMIN SUBHANI"
        rdate="1/15/2020"/>

  <ReviewsThumb/>

<ReviewsThumb 
        reviewstext="Would definitely recommend GEC. They took my daughter and her friends to their prom and brought them back. The car was lovely and the driver very polite and nice as were the staff in the office. I received pictures of the cars to choose from in advance and confirmations by text and e-mail and, most importantly the car arrived on time and wasn't too expensive."
        reviewerName="CATHERINE .HILL"
        rdate="7/10/2019"/>

<ReviewsThumb 
        reviewstext="Would definitely recommend and will be using again. Given we had two kids in tow- the chauffeur was courteous and patient. Easy to book, great communication, exceptional knowledge of London and prompt professional service. Thank you for everything!"
        reviewerName="OLIVIA"
        rdate="1/09/2020"/>

        </div>

                </div>
            </section>
        </>
    )
}

export default Reviews
