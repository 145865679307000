import { Link } from "react-router-dom"

const BlogThumbnails = (props) => {
    return (
        <div className="col-3 col-md-4 col-sm-6">
            <div className="blogThumbnail">
                <div className="img">
                <img src={props.thumbnail} alt="blog"/>
                <div className="ovelay"><span><i className="i-e-calendar" /> {props.postDate}</span></div>
                </div>
                <div className="content">
                    <h3>{props.blogheading}</h3>
                    <p>{props.shortDec}</p>
                    <div className="flex aib">     <span className="flexGrow-1"/>
                    <Link to={props.readmore} className="">Read More <i className="i-angle-right"/></Link></div>
               
                </div>
            </div>
        </div>
    )
}

BlogThumbnails.defaultProps = {
   blogheading: 'Best Things To Do In London ',
    shortDec: "Looking to tie the knot in London? London’s best-priced wedding venues that will offer the best value for your money.",
    thumbnail:process.env.PUBLIC_URL + "./assets/img/products/corporate.jpg",
    readmore:"/",
    postDate:"28th June 2020",
  };

export default BlogThumbnails
