
import PageBanner from "../components/PageBanner"



const Booking = () => {
    return (
        <>
             <PageBanner title="Booking"/> 
             <section className="preset offWhiteSec custombg">
                <div className="container">
                <h1 className="text-center">Reserve Your Car</h1>
              <form className="booking">
              <div className="row gutter5 aic">
                  <div className="col-6"> <button  className="link activetab"> ONE WAY</button></div>
          <div   className="col-6"><button  className="link"> BY THE HOURS</button></div> 
              </div>
              <div className="formGroup">
                  <label for="where-from">Where From</label>
              <input type="text" id="where-from" name="where-from" placeholder="Enter Collection Address*" required />
              </div>
          <div className="formGroup">
          <select>
                                    <option selected>Select a reason *</option>
                                    <option>Select a reason-1</option>
                                    <option>Select a reason-2</option>
                                    <option> Select a reason-3</option>
                                    <option>Select a reason-4</option>
                                </select>
          </div>
          <div className="formGroup">
          <textarea name="message" placeholder="Message*" rows={5} required defaultValue={""} />
          </div>
          <div className="text-center"><input type="submit" value="Submit" className="link submit"/></div>
              </form>
<div className="text-center gCar">
<img src={process.env.PUBLIC_URL + "/assets/img/greenVcar.svg"} alt="GEC logo"/>
</div>
                </div>
            </section>
        </>
    )
}

export default Booking
