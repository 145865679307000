import BlogThumbnails from "./BlogThumbnails"
import { Link } from "react-router-dom"
const HomeBlog = () => {
    return (
        <div className="preset">
<div className="container">
<h1>Blog</h1>
               <div className="divider"/> 
    <div className="row multiple">
      <BlogThumbnails/>
      <BlogThumbnails/>
      <BlogThumbnails/>
      <BlogThumbnails/>
    </div>
    <div className="moreb"><Link to="/" className="link">View All</Link></div>
</div>
        </div>
    )
}

export default HomeBlog
