import { Link } from "react-router-dom"
import PageBanner from "../components/PageBanner"


const Vehicles = () => {
    return (
        <>
             <PageBanner title="Vehicles" /> 
             <section className="preset offWhiteSec services">
                <div className="container">

                    {/* vehicles row */}
                    <div className="row vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/rolls-royce-ghost-series-ii-ewb.jpg"} alt="Corporate" /></div>
                        <div className="col-6 col-md-12">  <div className="vehicleC">
                            <h2>Rolls Royce Ghost Series II Ewb</h2>
                            <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 2 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link></div>
                      </div>
                    </div>

                     {/* vehicles row */}
                    <div className="row reverce vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/range-rover-autobiography-lwb.jpg"} 
                    alt="Corporate" /></div>
                        <div className="col-6 col-md-12">
                            <div className="vehicleC">
                        <h2>Range Rover Autobiography LWB</h2>
                            <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 3 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link></div>
                      </div>
                    </div>
                    
                     {/* vehicles row */}
                    <div className="row vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/mercedes-s-class.jpg"} alt="Corporate"/></div>
                    <div className="col-6 col-md-12"> <div className="vehicleC">
                     <h2>Mercedes S Class</h2>
                     <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 3 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link></div>
                      </div>
                    </div>

                    {/* vehicles row */}
                    <div className="row reverce vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/bentley-musalanne.jpg"} alt="Corporate"/></div>
                    <div className="col-6 col-md-12">
                    <div className="vehicleC">
                        <h2>Bentley Musalanne</h2>
                     <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 3 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link>
                    </div>
                      </div>
                    </div>

                     {/* vehicles row */}
                     <div className="row vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/audi-a8-lwb-hybrid.jpg"} alt="Corporate"/></div>
                    <div className="col-6 col-md-12"> <div className="vehicleC">
                     <h2>Audi A8 LWB Hybrid</h2>
                     <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 3 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link></div>
                      </div>
                    </div>

                    {/* vehicles row */}
                    <div className="row reverce vehicle">
                    <div className="col-6 col-md-12"><img src={process.env.PUBLIC_URL + "/assets/img/vehicles/bmw-7-series.jpg"} alt="Corporate"/></div>
                    <div className="col-6 col-md-12"> <div className="vehicleC">
                     <h2>BMW 7 Series</h2>
                     <ul className="list">
                    <li>World Class Travel</li>
                    <li>Holds 4 adults comfortably as well as 3 large suitcases and 2 carry-ons.</li>
                    <li>All our chauffeur vehicles come with free bottled water for all the passengers.</li>
                    <li>You can work, surf and check your email via free Wi-Fi.</li>
                    </ul>
                    <Link className="link">Book Now</Link></div>
                      </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Vehicles
