
import { NavLink } from "react-router-dom"
import Thumb from "../components/blogComponent/Thumb"
import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"
const Blog = () => {
    return (
     <>
        <div className="pagebanner">
        <div className="container"> 
        <HeadingwithLine htitle="Blogs" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
        </div>
        <PageContent padding="pd" >
 <div className="row ">
<div className="col-3 col-md-4">
 <div className="cotegory right">
   
   <h3>Category</h3>
   <NavLink to="blog" exact activeClassName="active" >Cockpit Of The Future <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Sustainable Mobility <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Corporate <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Corporate <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Ces Las Vegas <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Expert's Voice <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >Life At Faurecia <i className="i-angle-right"/></NavLink>
   <NavLink to="/" exact activeClassName="active" >All Blog <i className="i-angle-right"/></NavLink>
 </div>
</div>
<div className="col-9 col-md-8">
    <div className="thumbnails">
<div className="row multiple">
<Thumb goTo="/Blog-Details" />
<Thumb graphics={process.env.PUBLIC_URL + "./assets/img/female.jpg"} />
<Thumb />
<Thumb graphics={process.env.PUBLIC_URL + "./assets/img/female.jpg"} />
<Thumb />
<Thumb />
</div>
    </div>
    </div>
 </div>
</PageContent> 
     </>
    )
}

export default Blog
