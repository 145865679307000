import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-4 col-sm-6 col-xs-12">
                 
                    <img src={process.env.PUBLIC_URL + "./assets/img/cropped-gec-logo-s.png"} alt="GEC logo"/>
                        <span className="Flink">
                            <Link to="/"> <i className="i-call" /> : (+44) 020 8681 6533 </Link>
                            <Link to="/">
                                <i className="i-email" /> : info@gec-ltd.com</Link>
                  

                        </span>
                    </div>
                    <div className="col-4 col-md-4 col-sm-12 text-center sideLin">
                       <div className="maxWidth">
                       <h3>Company</h3>
                        <span className="centerlink">
                            <Link to="/"> Our Story</Link> |   
                            <Link to="/">  Contact</Link> | 
                            <Link to="/"> Terms & Condition</Link>
                        </span>
                        <span className="centerlink">
                            <Link to="/"> Privacy Policy</Link> | 
                            <Link to="/"> FAQ</Link>
                        </span>

                       </div>
                     

                    </div>
                    <div className="col-4 col-sm-12 col-xs-12 align-right ">
                   <div className="max-right">
                   <h3>LEARN</h3>
                        <div className="socialbox">
                    <a href="/" className="social"><i className="i-facebook"></i></a> 
                     <a href="/" className="social"><i className="i-twitter"></i></a>
                     <a href="/" className="social"><i className="i-instagram"></i></a>
                     <a href="/" className="social"><i className="i-linkedin"></i></a> 
                     </div>
                   </div>
                    </div>
  </div>
                <div className="copyright">
                © 2021 Gec-Ltd.com. All rights reserved.  <a href="/" > &nbsp; Privacy Terms of Service</a>
         </div>
            </div>
       
        </footer>
    )
}

export default Footer
