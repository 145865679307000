


const OffWhiteSec = () => {
    return (
        <section className="preset offWhiteSec">
            <div className="container">
               <h1>Why GEC is trusted</h1>
               <div className="divider"/>  
               <div className="row aic">
                    <div className="col-6">
                <div className="abt">
                <ul>
                            <li>Professional Team to assist you from start to finish</li>
                            <li>Bespoke Travel arrangement services </li>
                            <li>Safety, punctuality and comfortable</li>
                            <li>99.8% KPIs and above </li>
                            <li>Established since 2007</li>
                        
                        </ul>
                </div>
                    </div>
                   <div className="col-6">
                   <img className="preSetimg" src={process.env.PUBLIC_URL + "./assets/img/RR-NEW-PIC-A-1s.JPG"} alt="Car"/>
                   </div>
               </div>
            </div>
        </section>
    )
}

export default OffWhiteSec
