import Banner from "../components/Banner"
import SpecialMoments from "../components/homeComponents/SpecialMoments"
import OffWhiteSec from "../components/homeComponents/OffWhiteSec"
import Cars from "../components/homeComponents/Cars"
import Records from "../components/homeComponents/Records"
import DualColor from "../components/homeComponents/DualColor"
import HomeBlog from "../components/homeComponents/HomeBlog"


const Home = () => {
    return (
      <>
 <Banner/>
 <OffWhiteSec/>
 <Cars/>
 <Records/>
 <section className="preset">
 <SpecialMoments/>
 </section>
<DualColor/>
<HomeBlog/>
      </>
    )
}

export default Home
