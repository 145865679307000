import { Link } from "react-router-dom"

const Thumb = (props) => {
    return (
        <div className="col-4 col-md-6">
        <div className="thumb">
        <img src={props.thumbnailImg} alt="blog" />
        <h3>{props.heading}</h3>
        <p>{props.para}</p>
        <div className="readMore"> <Link to={props.goTo}><span>Read More</span></Link></div>
    
        </div>
    </div>
    )
}


Thumb.defaultProps = {
    thumbnailImg: process.env.PUBLIC_URL + "./assets/img/male.jpg",
    heading: "What is Lorem Ipsum ?",
    para:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    goTo:"/",
  };

export default Thumb
