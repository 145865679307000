import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Car from './Car';
const Cars = () => {
    return (
        <Carousel showArrows={true}  showStatus={false} > 
        <Car/>
        <Car/>
        <Car/>
        <Car/>
        </Carousel>
    )
}

export default Cars
