import HeadingwithLine from "../components/HeadingwithLine"
import PageContent from "../components/PageContent"

const ContactUs = () => {
    return (
        <>
    <div className="pagebanner">
        <div className="container"> 
        <HeadingwithLine htitle="Contact Us" linecolor="flexGrow-1 line white" headingClass="sectionTitle white" /></div>
        </div>
<PageContent>
 <div className="row">

 <div className="col-12">
 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.365371153965!2d10.022532115814831!3d53.551244880022494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b18eebeb55755f%3A0x7cb0efe532804256!2sSpaldingstra%C3%9Fe%20210%2C%2020097%20Hamburg%2C%20Germany!5e0!3m2!1sen!2sin!4v1619266149879!5m2!1sen!2sin" style={{border: 0, width: '100%', height: '280px'}} title="map" allowFullScreen loading="lazy" />
         </div>
         <div className="col-6 col-xs-12">
         <div className="Contactde flex">
        <i className="i-edit"/>
        <div>
            <h2>Location:</h2>
            <p>Spaldingstraße 210, 20097 Hamburg Germany</p>
        </div>
         </div>
         <div className="Contactde flex">
        <i className="i-email"/>
        <div>
            <h2>Email:</h2>
            <p>info@example.com</p>
        </div>
         </div>
         <div className="Contactde flex">
        <i className="i-call"/>
        <div>
            <h2>Call:</h2>
            <p>+49 40 88366160</p>
        </div>
         </div>
     </div>
  
 <div className="col-6 col-xs-12 form">
       <h2 className="">Quick Enquiry</h2>
   <form action>
        <input type="text" name="name" placeholder="Name" required />
        <input type="email" name="email" placeholder="Email" required />
        <input type="number" name="mobile" placeholder="Mobile" required />
        <input type="text" name="subject" placeholder="subject" />
        <textarea name="message" placeholder="Massage" rows={5} required defaultValue={""} />
         <button type="submit" className="link submit">Send Message</button>
      
        </form>
   </div>
 </div>
</PageContent> 


</>
    )
}

export default ContactUs
