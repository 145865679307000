import {NavLink} from "react-router-dom";

export const Services = (props) => {
    return (
<div className="megamenu">
    <div className="backdrop">
<div> 
<NavLink onClick={props.click} to="/corporate-service">Corporate</NavLink>
<NavLink onClick={props.click} to="/weddings-service">Weddings</NavLink>
<NavLink onClick={props.click} to="/airport-transfers-service">Airport Transfers </NavLink>
<NavLink onClick={props.click} to="/special-occasions-service">Special Occasions</NavLink>
<NavLink onClick={props.click} to="/concierge-services-service">Concierge Services</NavLink>

</div>
</div>
</div>

    )
}


export const Vehicles = (props) => {
    return (
        <div className="megamenu">
        <div className="backdrop">
    <div>
    <NavLink onClick={props.click} to="/">Sub Menu</NavLink>
<NavLink onClick={props.click} to="/">Sub Menu2 </NavLink>
<NavLink onClick={props.click} to="/">Sub Menu3 </NavLink>
<NavLink onClick={props.click} to="/">Sub Menu4 </NavLink>
    </div>
    </div>
    </div>
    
      )
}
