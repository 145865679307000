import { Link } from 'react-router-dom';
const EventsTabs = () => {
    return (
        <div className="flex aic tabs">
        <div><img src={process.env.PUBLIC_URL + "./assets/img/smallIcon/World-Class-Travel.jpg"} alt="feature"/></div>
        <div className="shortDec">
            <h3 className="title">A week in the life of a chauffeur</h3>
            <p>September 2 2021</p>
            </div>
            <div className="goto">
              <Link to="about"><i className="i-angle-right"/></Link>
            </div>
                </div>
    )
}

export default EventsTabs
