import { Link } from "react-router-dom"
const QuickAction = () => {
    return (
        <div className="quickAction">
           <Link to="/" className="number"><i className="i-call" /> &nbsp; (+44) 020 8681 6533</Link> 
           <Link to="/"><i className="i-whatsapp" /></Link> 
           <Link to="/"><i className="i-whatsapp"/></Link> 
        </div>
    )
}

export default QuickAction
