import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
const GoogleReviews = () => {
    return (
        <div className="google-reviews text-left">
        <h1>
        Google Reviews
        </h1>
        <div className="divider"/>
        <Carousel showArrows={false}  showStatus={false} > 
    <div className="reviews">
        <p>Would definitely recommend GEC. They took my daughter and her friends to their prom and brought them back. The car was lovely and the driver very polite and nice as were the staff in the office. I received pictures of the cars to choose from in advance and confirmations by text and e-mail and, most importantly the car arrived on time and wasn't too expensive.</p>
        <div className="flex aic">
        <div className="usericon"> <i className="i-user" /> </div>
        <span>CATHERINE .HILL</span>
        </div>
    </div>  
    <div className="reviews">
        <p>Excellent service by this environmentally friendlier chauffeur service for my wedding. Absolute pleasure to work with really nice people and I will not only recommend them to my family and friends but also the general public.</p>
        <div className="flex aic">
        <div className="usericon"> <i className="i-user" /> </div>
        <span>JAY S</span>
        </div>
    </div> 
    <div className="reviews">
        <p>Would definitely recommend and will be using again. Given we had two kids in tow- the chauffeur was courteous and patient. Easy to book, great communication, exceptional knowledge of London and prompt professional service. Thank you for everything!</p>
        <div className="flex aic">
        <div className="usericon"> <i className="i-user" /> </div>
        <span>OLIVIA</span>
        </div>
    </div> 
    </Carousel>
    <Link to="/reviews" className="link">See All Reviews</Link>
    </div>

    )
}

export default GoogleReviews
