
import ProductsThumbs from "../ProductsThumbs"

const SpecialMoments = () => {
    return (
        <div className="container bst">
           <h1>Special Moments</h1>
               <div className="divider"/> 
         <div className="row">
<ProductsThumbs explore="/corporate-service" />
<ProductsThumbs title="Weddings" 
discription="We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings"
 chBg="gray10" 
  pictures={process.env.PUBLIC_URL + "./assets/img/products/wedding.jpg"}  explore="/weddings-service" />

<ProductsThumbs title="Airport Transfers" 
discription="We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings" 
  pictures={process.env.PUBLIC_URL + "./assets/img/products/airport-transfers.jpg"}  explore="/airport-transfers-service" />

<ProductsThumbs title="Special Occasions" 
discription="We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings" 
chBg="gray10"
  pictures={process.env.PUBLIC_URL + "./assets/img/products/special-occasion.jpg"}  explore="/special-occasions-service" />

<ProductsThumbs title="Concierge Services" 
discription="We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings" 
  pictures={process.env.PUBLIC_URL + "./assets/img/products/yatch.jpg"}  explore="/concierge-services-service" />

<ProductsThumbs chBg="gray10"  explore="/" />
 </div>
        </div>
    )
}

export default SpecialMoments
