
import PageBanner from "../components/PageBanner"

const SpecialOccasions = () => {
    return (
        <>
            <PageBanner title="Special Occasions" />
            <section className="preset offWhiteSec page services">
                <div className="container">
                   <div className="flex">
                        <div className="flexGrow-1">
                        <p>Special Occasions can be marriages, family gatherings or trips, birthday parties, stag and hen parties. We specially make programmes for our clients based on the occasion.</p>
                            <p>Please <a href="/contact-us">contact us</a> for a list of programmes specially designed for our customers. If you would like to create your own programme, then we would be obliged if you could provide us with as much information as possible for us to make a programme that can cater to all your needs and make the journey as pleasant as possible.</p>
                            <h2>Family Dayout</h2>
                            <p>Enjoy an amazing family day-out with GEC. Fancy a personalized family outing to a place of your choice: book a restaurant, go to Disneyland, explore British heritage in luxury with Green Executive Cars. We also have special family package trips to beautiful places. Make use of our concierge services and enjoy the complimentary confectionery or drinks.
                            </p>
                        </div>
                        <div className="servicesimage">  <img src={process.env.PUBLIC_URL + "/assets/img/products/sp.jpg"} alt="Corporate" /></div>
                    </div>

                    <div className="flex aic pdtp-20">
                    <div className="servicesimage left">  <img src={process.env.PUBLIC_URL + "/assets/img/products/ascot.jpg"} alt="Corporate" /></div>
                        <div className="flexGrow-1">
                            <h2>UK Event</h2>
                            <p>Please select from the following list of events:</p>
                            <ul className="list">
                        <li>Ascot</li>
                        <li>Epsom Downs derby</li>
                        <li>Farnborough Air show</li>
                        <li>Horse Racing Festivals and Events</li>
                        <li>Personal trips</li>
                      
                    </ul>
                            <p>We conduct half-day and daylong trips to places of interest in London. Please <a href="/contact-us">contact us</a> for further information. </p>
                        </div>
                       
                    </div>

                  
                </div>
            </section>
        </>
    )
}

export default SpecialOccasions
