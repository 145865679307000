
const HeadingwithLine = (props) => {
    return (
        <div className="flex headingwithLine aic">
       <h2 className={props.headingClass}>{props.htitle}</h2>
     <div className={props.linecolor} />
    <a href={props.goTo} className="viewmore">VIEW MORE</a>
        </div>
    )
}
 HeadingwithLine.defaultProps = {
    htitle: 'About Us',
    linecolor: "flexGrow-1 line",
    headingClass:"sectionTitle",
    goTo:"/"
  };
  
export default HeadingwithLine
