
const ReviewsThumb = (props) => {
    return (
        <div className="col-6 col-sm-12"> 
        <div className="greviews">
      <p>{props.reviewstext}</p>
       <div className="flex wrap aic">
           <div className="usericon"> <i className="i-user" /> </div>
          <div className="flexGrow-1">
          <h2 >{props.reviewerName}</h2>
          <span>{props.rdate}</span>
          
          </div>
           <div className="star">{props.star}</div>
       </div>
      </div>
           </div>
    )
    
}
ReviewsThumb.defaultProps = {
    reviewstext:"Excellent service by this environmentally friendlier chauffeur service for my wedding. Absolute pleasure to work with really nice people and I will not only recommend them to my family and friends but also the general public.",
reviewerName:"JAY S",
rdate:"3/09/2020",
star:"★★★★★",
  }
export default ReviewsThumb
