
import PageBanner from "../components/PageBanner"

const Weddings = () => {
    return (
        <>
            <PageBanner title="Weddings" />
            <section className="preset offWhiteSec page services">
                <div className="container">
                    <div className="flex">
                        <div className="flexGrow-1">
                            <p>We Specialise In Providing A High Quality Wedding Chauffeur Service. Vast Fleet of Luxury Cars to make your Weddings special. Hassle Free Booking. Book your Wedding Cars. Chauffeur Drive Cars. Prestige Luxury Cars.</p>

                            <p>
                                You should think about your transportation around four and six months prior the settled date, ceremony and reception sites and wedding party size. April, May or June is prom and graduation season, high-class vehicles will be in high demand, so you’ll need to book your transportation even earlier.</p>

                            <p>We know that cost is an important factor when booking your wedding chauffeur car. We have deliberately chosen to keep our pricing simple. We have designed our Wedding Car Packages to cater for both the traditional Church wedding as well as the very popular but shorter Civil Ceremony. Our wedding packages are tailor made to meet your individual needs and budget. Make your wedding day spectacular and seamless with our exclusive & luxurious service.</p>
                        </div>
                        <div className="servicesimage">  <img src={process.env.PUBLIC_URL + "/assets/img/products/wedding.jpg"} alt="Corporate" /></div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Weddings
