const PageBanner = (props) => {
    return (
        <div className="pagebanner">
            <div className="container ">
                <h1 className="text-left">{props.title}</h1>
            </div>
        </div>
    )
}
export default PageBanner
