
import PageBanner from "../components/PageBanner"

const ConciergeServices = () => {
    return (
        <>
            <PageBanner title="Special Occasions" />
            <section className="preset offWhiteSec page services">
                <div className="container">
                   <div className="flex">
                        <div className="flexGrow-1">
                        <p>To London many visitors, our corporate and regular clients, we provide concierge services. Our concierge services include collecting medicines from the local pharmacy to booking a place in an elite London restaurant.</p>
                        <p>Arrangements at a short notice</p>
                        
                            <p>We are also affiliated with the prominent hotels in London where we can arrange accommodation for our clients at a short notice.</p>

                        
                            <p>With a plethora of exotic vehicles available upon request, GEC provides customers with the means to make their journey or entrance luxurious as possible. From classy sports manufactures ranging from Aston Martin to Jaguar, <b>GEC</b> has a car to suit all occasions and distinguished tastes. We also offer PCO licensed vehicles for self-drive. </p>

                        </div>
                        <div className="servicesimage">  <img src={process.env.PUBLIC_URL + "/assets/img/products/lamborghini-page.jpg"} alt="Corporate" /></div>
                    </div>
<h2>VIPs & Protection</h2>
<p><b>GEC</b> offers a complete security package solution to VIP clients. This includes close protection, vehicle and route planning as well as round the clock assistance for the principle.</p>
<h2>Private Jets, Helicopters & Yachts</h2>
<p>GEC offers a complete security package solution to VIP clients. This includes close protection, vehicle and route planning as well as round the clock No matter whether you arrive or transfer by land, sea or air, <b>GEC</b> offers an efficient and reliable, full service travel solution for clients, in the UK and internationally.</p>

                    <div className="flex aic pdtp-20">
                
                        <div className="flexGrow-1">
                            <h2>Entertainment & Film Events</h2>
                            <p>We cater for a broad range of entertainment requirements including luxury car fleets for red carpet film premieres or providing cars for film and fashion shoots.</p>
                    
                            <p>Office Services are also provided like printing, scanning, fax, internet, booking of meeting rooms, conference rooms or halls to our customers on request.</p>
                            <p>The core reason for concierge service is not commercial, but to provide maximum satisfaction possible service with facilities to create a comfortable and luxury environment for our clientele.</p>
                            <h2>Luxury & Exotic Car Storage</h2>
                            <p>Available upon request</p>
                        </div>
                        <div className="servicesimage">  <img src={process.env.PUBLIC_URL + "/assets/img/products/yatch-page.jpg"} alt="Corporate" /></div>
                    </div>
<p><b>GEC</b> offers a storage service to clientele who require high level security with complete peace of mind. With valet service also available, luxury vehicle owners can rest assured their car is in safe hands.</p>
<h2>Luxury Exotic Car Hire</h2>
<p>Available on request </p>
                </div>
            </section>
        </>
    )
}

export default ConciergeServices
