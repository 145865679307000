import CountUp from 'react-countup';
const Records = () => {
    return (
        <div className="preset offWhiteSec">
         <div className="container">
<div className="row multiple">
    <div className="col-4 col-sm-6">
        <div className="records">
            <div className="reFor">Passengers <span>
                <CountUp startOnMount={true} CountUp delay={3} duration={20} end={10285}/> </span></div>
        </div>
    </div>
    <div className="col-4 col-sm-6">
        <div className="records">
            <div className="reFor">Passengers  <CountUp CountUp delay={3} duration={20} end={10285}/></div>
        </div>
    </div>
    <div className="col-4 col-sm-12">
        <div className="records">
            <div className="reFor">Passengers  <CountUp CountUp delay={3} duration={20} end={10285}/></div>
        </div>
    </div>
</div>
         </div>
        </div>
    )
}

export default Records
