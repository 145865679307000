import GoogleReviews from './GoogleReviews';
import EventsTabs from './EventsTabs';
const DualColor = () => {
    return (
        <div className="dualColor">
            <div className="container">
              <div className="row">
                  <div className="col-6 col-sm-12">
                      <GoogleReviews/>
               </div>
               <div className="col-6 col-sm-12">
               <div className="event">
               <h1>
       Events
        </h1>
        <div className="divider"/>
   <EventsTabs/>
   <EventsTabs/>
   <EventsTabs/>
   <EventsTabs/>
               </div>
               </div>
              </div>
            </div>
        </div>
    )
}

export default DualColor
