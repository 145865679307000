import Header from '../src/assets/components/Header';
import Footer from '../src/assets/components/Footer';
import Home from '../src/assets/pages/Home';
import { Switch, Route} from "react-router-dom";
import ContactUs from './assets/pages/ContactUs';
import Blog from './assets/pages/Blog';  
import BlogDetails from './assets/pages/BlogDetails';   
import ScrollToTop from "./assets/components/ScrollToTop";
import OurStory from './assets/pages/OurStory';
import Corporate from './assets/pages/Corporate';
import Weddings from './assets/pages/Weddings';
import AirportTransfers from './assets/pages/AirportTransfers';
import SpecialOccasions from './assets/pages/SpecialOccasions';
import ConciergeServices from './assets/pages/ConciergeServices';
import Vehicles from './assets/pages/Vehicles';
import Reviews from './assets/pages/Reviews';
import Booking from './assets/pages/Booking';


function App() {
  return (
<>
<Header/>
<ScrollToTop/>
<Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/our-story" component={OurStory}/>
        <Route path="/corporate-service" component={Corporate}/>
        <Route path="/weddings-service" component={Weddings}/>
        <Route path="/airport-transfers-service" component={AirportTransfers}/>
        <Route path="/special-occasions-service" component={SpecialOccasions}/>
        <Route path="/concierge-services-service" component={ConciergeServices}/>
        <Route path="/vehicles" component={Vehicles}/>
        <Route path="/reviews" component={Reviews}/>
        <Route path="/reserve-your-car" component={Booking}/>
        <Route path="/Contact-Us" component={ContactUs}/>
        <Route path="/blog" component={Blog}/>
        <Route path="/Blog-Details" component={BlogDetails}/>
      </Switch>
    <Footer/>
</>
  );
}

export default App;
