import { Link } from "react-router-dom";
const ProductsThumbs = (props) => {
    return (
        <div className="col-4 col-md-6 col-sm-6 col-xs-12">
     
        <div className={"productThumbs " + props.chBg}>
        <img src={props.pictures} alt="tea" />
        <h3>{props.title}</h3>
        <p>{props.discription}</p>
        <Link to={props.explore} className="link">Read More</Link>
        </div>
    </div>
    
    )
}
ProductsThumbs.defaultProps = {
    title: 'Corporate',
    discription: "We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings",
    pictures:process.env.PUBLIC_URL + "./assets/img/products/corporate.jpg",
    explore:"/"
  };
  
export default ProductsThumbs
