
import PageBanner from "../components/PageBanner"

const Corporate = () => {
    return (
        <>
            <PageBanner title="Corporate " />
            <section className="preset offWhiteSec page services">
                <div className="container">
                    <div className="flex">
                        <div className="flexGrow-1">
                            <p>We offer corporate chauffeur services in luxury vehicles in and around London. Take a transfer between hotels & airport, to events or business meetings. Perfection Is More Than A Promise. The Most Luxurious Service On The Market.</p>

                            <p>Our Chauffeur’s services include fetching clients, driving them to specified locations, and ensuring that the vehicle remains in excellent condition. We also aid customers with the loading and unloading of their personal belongings. Our Chauffeurs are able to resolve clients’ queries and complaints promptly.</p>

                            <p>Our <b>Chauffeurs</b> liaise with you to determine when and where to pick from. Ensure that your door is open as they greet you in a professional, sincere manner. Assisting you with the loading and unloading of your possessions, as required. Adjust the air temperature, if required by you. Select the fastest routes based on GPS software and traffic updates, unless otherwise instructed. Complete collections and deliveries on behalf of you, if requested. Abide by road regulations at all times. Forecast and respond to your questions and concerns.</p>

                            <p><b>GEC</b> has punctuality at its core. Our business customers for whom timeliness and punctuality mean everything; we are the best in our industry. <b>Corporate customers</b> can use our services for their special business needs.</p>

                            <p>Business needs can be easily catered and customised as per daily hire, weekly hire or limited period contracts. The business customers can make use of our <a href ="/airport-transfers-service">airport services</a>, special occasion services and concierge services.</p>

                            <p>We cater to road shows, business meetings, executive conferences, business travel, promotions, private jet transfers and other business needs. We have vast experience to cater for film production units carrying crews and celebrities to concerts and other media events.</p>

                            <p>We understand that businesses are dynamic and provide our services accordingly.</p></div>
                        <div className="servicesimage flexGrow-1 ">  <img src={process.env.PUBLIC_URL + "/assets/img/products/corporate-page.jpg"} alt="Corporate" /></div>
                    </div>


                </div>
            </section>
        </>
    )
}

export default Corporate
