import PageBanner from "../components/PageBanner"
const OurStory = () => {
    return (
        <>
    <PageBanner title="Our Story" />
        <section className="preset services our-story">
            <div className="container">
            <p>Green Executive Cars has developed since 2007 into one of the market leaders in providing luxury chauffeur driven services with minimum carbon footprint. We provide bespoke travel arrangement from individuals to corporations with a vast choice of vehicles to choose from. Our standards are unparalleled. We get grips with your taste and requirement to develop  and improvise on an  ultimate travel experience.
          </p>
          <p>When it comes to chauffeuring, GEC is in a league of its own. GEC provides a luxurious experience of comfort, safety and class. We take the responsibility for our planet hence have a fleet of luxury cars with minimum or zero impact on the environment. We are a chauffeur company with a difference, offering the best services at every opportunity to make your journey a pleasurable experience. We are a London-based company with years of experience in our industry.</p>
               <p>Customer Safety is our utmost priority at all times. Our Chauffeurs are selected by their training, experience and professionalism. Our modern and a new fleet of cars provide exceptional safety standards and our staff is specially trained for ensuring safety standards are maintained at all times.</p>
                <h2> Punctuality –</h2>
               <p>All our cars arrive at the pickup destination fifteen minutes prior to booking time.</p>
               <h2>Flexibility – </h2>
               <p>We are flexible with our clients, and allow for ad-hoc services when required. We understand that each customers’ needs are different and hence cater for this.</p>
               <p>Our vision is to become unrivalled in the elite chauffeuring industry.</p>
           
                   <div className="flex aic pdtp-20">
                    <div className="servicesimage left">  <img src={process.env.PUBLIC_URL + "/assets/img/rolls-royce-light-colour.jpg"} alt="Corporate" /></div>
                        <div className="flexGrow-1">
                        <h2 className="pdbt-10">Why GEC is trusted</h2>
                            <ul className="list">
                        <li>Professional Team to assist you from start to finish</li>
                        <li>Bespoke Travel arrangement services</li>
                        <li>Safety, punctuality and comfortable</li>
                        <li>99.8% KPIs and above</li>
                        <li>Established since 2007</li>
                   </ul>
                            <p>We conduct half-day and daylong trips to places of interest in London. Please <a href="/contact-us">contact us</a> for further information. </p>
                        </div>
                       
                    </div>
            
                </div>  
        </section>
        </>
    )

}
export default OurStory
