import { useState } from "react";
import QuickAction from "./QuickAction";

import {NavLink} from "react-router-dom";
import {Services}  from './submenu/Submenu';
function Header(){
const [header, setHeader] = useState(false);
const [toggle, settoggle] = useState(false);
const toggler = () =>{
    toggle ? settoggle(false) : settoggle(true);
}
const changebg = () =>{
   if(window.scrollY > 40){
       setHeader(true);
   }
   else{
       setHeader(false);
   }
}
window.addEventListener('scroll', changebg);
    return (
        <>
             <header className={header ? 'header active' : 'header' }>
   
   <div className="container nbar  csb">
<div className="brand"><img src={process.env.PUBLIC_URL + "/assets/img/gec-logo.png"} alt="GEC logo"/></div>
<div className="navebar">

<ul className={toggle ? "nav opened" : "nav"}>
<li><NavLink onClick={toggler} to="/our-story" exact activeClassName="activelink">Our Story</NavLink></li>
<li><NavLink onClick={toggler} to="/" exact activeClassName="activelink">Home</NavLink></li>
<li><NavLink onClick={toggler} to="/" exact activeClassName="activelink">Blog</NavLink></li>
<li><NavLink onClick={toggler} to="/reviews" exact activeClassName="activelink">Reviews</NavLink></li>

</ul>
</div>
  
<div className="other">
<ul className="otherNav">

<li className="submenu navtab"><i className="i-e-gear"/> Services <i className="i-angle-down"/>
<Services click={toggler}  /></li>

<li><NavLink to="/vehicles" className="submenu navtab" activeClassName="activetab"><i className="i-e-car"/>  Vehicles </NavLink>
</li>
<li ><NavLink to="/reserve-your-car" className="submenu navtab green"><i className="i-e-calendar"/> Reserve Your Car</NavLink></li>
</ul>

<button onClick={toggler} className={toggle ? "menubutton opened" : "menubutton"}>
<span></span><span></span><span></span>
</button> 
</div>
   </div>
</header> 
<QuickAction/>
        </>
 
    )
}

export default Header
