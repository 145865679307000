
import PageBanner from "../components/PageBanner"

const AirportTransfers = () => {
    return (
        <>
            <PageBanner title="Airport Transfers" />
            <section className="preset offWhiteSec page services">
                <div className="container">
                    <p>Airport Transfers To And From Any Part Of The London 24 hours A Day 7 Days A Week. Clean & Comfortable Cars. Executive Cars. Get an Instant Qoute. Types: Saloons, Estates, MPV, Executive Cars, 8 Seaters, 12 Seaters.</p>
                    <div className="flex">
                        <div className="flexGrow-1">
                            <p><b>GEC</b> offers a reliable and premium <b>airport transfer</b> service from all England airports. All our drivers are experienced, fully qualified and professional. <a href="/booking">Booking</a> will be confirmed in advance with the driver, and both car and driver details will be provided. We have a selection of <a href="/vihicle">Prestigious Cars</a> for all our Airport Transfers.</p>

                            <p> <b>GEC</b> provides the ultimate experience in luxury travel to our valued clients…</p>
                            <h1>UK Airport Transfer: Arrivals</h1>

                            <p>We will monitor and coordinate your flight arrival with the Chauffeur in order to minimise waiting time. Our drivers will meet and greet you at the arrival. Airport transfers couldn’t be easier.</p>
                        </div>
                        <div className="servicesimage">  <img src={process.env.PUBLIC_URL + "/assets/img/products/airport-transfers-page.jpg"} alt="Corporate" /></div>
                    </div>

                    <p><b>Green Executive</b> Cars is faster, easier and more comfortable. It is door-to-door service which allows you to travel directly between the airport and your accommodation with the least effort.</p>

                    <p>The transfer fare is the final price and you don’t have to pay for any extra costs. We work with fixed prices so before you book you know what you have to pay.</p>

                    <p>We accept all major online payment methods: VISA, MasterCard, American Express, Paypal. All are secure.</p>

                    <p><b>Heathrow Airport</b>, also known as London Heathrow, is a major international airport in London, United Kingdom. Heathrow is the busiest airport in the world by international passenger traffic, as well as the busiest airport in Europe by passenger traffic, and the seventh busiest airport in the world by total passenger traffic. It is one of six international airports serving Greater London.</p>

                    <h1>UK Airport Transfer: Departures </h1>
                    <p><b>GEC Chauffeurs</b> always arrive prior to the time of your airport transfer for your convenience. You can sit back and relax knowing that you will be driven door-to-door without any hassle.</p>

                    <p>Airport transfer Chauffeur Service covering all main London & UK Airports, including:</p>
                    <ul className="list">
                    <li>London Heathrow Airport Chauffeur</li>
                    <li>London Gatwick Airport Chauffeur</li>
                    <li>London Stansted Airport Chauffeur</li>
                    <li>London City Airport Chauffeur</li>
                    <li>Luton Airport Chauffeur</li>
                    <li>Farnborough Airport Chauffeur</li>
                    <li>Northolt Airport Chauffeur</li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default AirportTransfers
